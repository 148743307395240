export default {
  breakpoints: ["40em", "48em", "64em"],
  space: [0, 4, 8, 16, 32, 64, 128, 256, 512],
  fontSizes: [12, 14, 16, 20, 24, 32, 48, 64, 96],
  sizes: ["100%", "70%"],
  fonts: {
    body: "Rubik, system-ui, sans-serif",
    title: "Rubik, system-ui, sans-serif",
    heading: "Sriracha, system-ui, sans-serif",
    monospace: "Menlo, monospace",
  },
  fontWeights: {
    light: 300,
    body: 400,
    medium: 500,
    bold: 700,
    heavy: 900,
    heading: 400,
  },
  lineHeights: {
    body: 1.618,
    list: 1.25,
    heading: 1.05,
  },
  colors: {
    text: "#222",
    background: "#fff",
    // background: 'repeating-linear-gradient(45deg, transparent, transparent 10px, black 10px, black 20px) white',
    backgroundT: "#ffffffe0",
    darkgray: "#333",
    gray: "#666",
    lightgray: "#999",
    primary: "#E9204F",
    // primary: '#E9204F',
    // primary: '#ff7300',
    primaryTransparent: "#ED4B7040",
    // primaryTransparent: '#ff730040',
    primaryHalf: "#F8587B",
    postit: "#fff93f",
    postitblue: "#3db5ff",
    // primaryHalf: '#f7b87e',
    secondary: "#f00",
    muted: "#f6f6f6",
    gradi:
      "linear-gradient(45deg, hsla(27, 100%, 50%, 1) 0%, hsla(340, 100%, 50%, 1) 100%)",
  },
  shadows: {
    project: {
      shadow: "1px 1px 2px rgba(0,0,0,0.75)",
      aboutCircles: "0px 5px 5px 3px rgba(0,0,0,0.25)",
      postit:
        "inset 0 -3em 3em rgba(111,111,111,0.1), 2px 2px 3px 1px rgba(0, 0, 0, .25)",
      paper: "inset 0 0 0 .1em hsla(0,0%,0%,.1), inset 0 0 1em hsla(0,0%,0%,.05), 0 .1em .25em hsla(0,0%,0%,.5)",
      paperBottom: "0 0 .5em .5em hsla(0,0%,0%,.25)"
    },
  },
  borders: {
    project: {
      underline: "solid primary 5px",
    },
  },
  styles: {
    root: {
      "@keyframes rotateText": {
        "0%": {
          transform: "rotate(0)",
        },
        "100%": {
          transform: "rotate(360deg)",
        },
      },
      fontFamily: "body",
      lineHeight: "body",
      fontWeight: "light",
      textRendering: "geometricPrecision",
      "-webkit-font-smoothing": "antialiased",
      "-moz-osx-font-smoothing": "gray",
      a: {
        textDecoration: "none",
        color: "primary",
      },
      strong: {
        fontWeight: "medium",
        backgroundColor: "postit",
        color: "text",
        px: "1px",
        mr: "-1px",
      },
      "*": {
        marginBlockStart: 0,
        marginBlockEnd: 0,
      },
      ".projectMDX hr": {
        gridColumnStart: "span 12",
      },

      ".projectHeader": {
        gridColumnStart: "1",
        gridColumnEnd: "4",
        outline: "1px solid transparent",
        // fontWeight: 'light',
        alignSelf: "start",
        textAlign: ["left", "right"],
        my: "3",
        mr: "2",
      },
      ".projectHeader > h2": {
        textTransform: "uppercase",
        fontSize: "3",
        // fontWeight: 'light',
      },
      ".projectHeader > h3": {
        textTransform: "uppercase",
        fontSize: "2",
        // fontWeight: 'light',
      },
      ".projectHeader > h4": {
        textTransform: "uppercase",
        fontSize: "1",
      },
      ".projectHeader > h2 > span": {
        backgroundColor: "primaryHalf",
        p: "2",
        pl: "3",
        boxShadow: "project.postit",
        outline: "1px solid transparent",
        cursor: "default",
        lineHeight: "body",
        whiteSpace: "nowrap",
      },
      ".projectHeader > h3 > span": {
        backgroundColor: "postit",
        p: "2",
        pl: "3",
        boxShadow: "project.postit",
        outline: "1px solid transparent",
        cursor: "default",
        lineHeight: "body",
        whiteSpace: "nowrap",
      },
      ".projectHeader > h4 > span": {
        backgroundColor: "postitblue",
        p: "2",
        pl: "3",
        boxShadow: "project.postit",
        outline: "1px solid transparent",
        cursor: "default",
        lineHeight: "body",
        whiteSpace: "nowrap",
      },
      ".projectMDX p": {
        gridColumnStart: "4",
        gridColumnEnd: "9",
        fontSize: "3",
      },
      ".projectImage": {
        //images
        // gridColumnStart: 'span 12',
        gridColumnStart: "4",
        gridColumnEnd: "13",
      },
      ".projectMDX ul, ol, li, blockquote": {
        gridColumnStart: "4",
        gridColumnEnd: "9",
        fontSize: "3",
      },
      ".projectMDX ul>li": {
        display: "list-item",
        listStyle: "disc",
      },
      ".projectMDX ol>li": {
        display: "list-item",
        listStyle: "decimal",
      },
      ".projectMDX ul, ol,p,.projectImage": {
        //
        my: ["3", "2"],
      },
      ".projectMDX li": {
        borderBottom: "1px dashed",
        borderBottomColor: "primary",
      },
      ".projectMDX table": {
        // gridColumnStart: 'span 12',
      },
      ".projectMDX blockquote": {
        marginInlineStart: "0",
        marginInlineEnd: "0",
      },
      "#behanceIcon": {
        color: "gray",
        ":hover": {
          color: "#053eff",
        },
      },
      "#linkedinIcon": {
        color: "gray",
        ":hover": {
          color: "#0072b1",
        },
      },
      ".circles": {
        fill: "lightgray",
        cursor: "help",
        strokeWidth: "3",
        stroke: "background",
        transition: "fill .25s ease-in, stroke-width .25s ease-in!important",
        ":hover": {
          fill: "primaryHalf",
        },
      },
      ".circlesText": {
        fill: "background",
        fontWeight: "medium",
        letterSpacing: "2px",
        transformOrigin: "50% 50%",
        fontSize: "3",
      },
      ".circles.active": {
        fill: "primary",
        strokeWidth: "5",
      },

      //   '#projectBox::after': {
      //     backgroundColor: '#000',
      //     bottom: 0,
      //     content: "''",
      //     left: 0,
      //     opacity: .1,
      //     position: 'absolute',
      //     right: 0,
      //     top: 0,
      // },

      "h2:empty": {
        display: "none",
      },
      "#projectBox img": {
        transition: "transform .25s ease-in!important",
      },
      "#projectBox:hover img": {
        transform: "scale(1.15)",
      },
      ".Typewriter__cursor": {
        color: "primary",
      },
      ".typed": {
        display: "initial",
      },
      // 'a::after': {
      //   background: 'none repeat scroll 0 0 transparent',
      //   bottom: 0,
      //   content: '""',
      //   display: 'block',
      //   height: '2px',
      //   left: '50%',
      //   position: 'absolute',
      //   backgroundColor: 'primary',
      //   transition: 'width 0.3s ease 0s, left 0.3s ease 0s',
      //   width: '0',
      // },
      // 'a:hover::after': {
      //   width: '100%',
      //   left: 0,
      // },
      // '.navi.naviactive': {
      //   borderBottom: '4px solid',
      //   ':hover': {
      //     cursor: 'default',
      //   },
      // },
      ".navi.naviactive": {
        color: "primary",
        borderBottom: "2px solid",
        ":hover": {
          cursor: "default",
        },
      },
      ".navi": {
        transition: "color 0.3s ease-in",
        fontWeight: "body",
        color: "gray",
        ":hover": {
          color: "primary",
        },
        fontSize: [1, 2],
      },
      hr: {
        borderStyle: "none",
        borderTop: "2px dashed",
        color: "primary",
        textAlign: "center",
        height: "5px",
      },
      h1: {
        fontFamily: "heading",
        lineHeight: "heading",
        fontWeight: "heading",
        fontSize: "6",
        mb: 3,
      },
      h2: {
        fontFamily: "body",
        lineHeight: "list",
        fontWeight: "medium",
        fontSize: "5",
      },
      h3: {
        fontFamily: "body",
        lineHeight: "list",
        fontWeight: "medium",
        fontSize: "4",
      },
      h4: {
        fontFamily: "body",
        lineHeight: "list",
        fontWeight: "body",
        fontSize: "3",
      },
      h5: {
        fontFamily: "body",
        lineHeight: "heading",
        fontWeight: "body",
        fontSize: "2",
      },
      h6: {
        color: "text",
        fontFamily: "body",
        lineHeight: "heading",
        fontWeight: "heading",
        fontSize: "1",
      },
      p: {
        color: "text",
        fontFamily: "body",
        fontWeight: "light",
        lineHeight: "body",
        fontSize: 3,
      },
      li: {
        mb: "2",
      },
      ul: {
        color: "text",
        fontFamily: "body",
        lineHeight: "body",
        listStyleType: "none",
        paddingInlineStart: "0",
        fontSize: 3,
      },
      ol: {
        color: "text",
        fontFamily: "body",
        lineHeight: "body",
        listStyleType: "none",
        paddingInlineStart: "0",
        fontSize: 3,
      },
      pre: {
        fontFamily: "monospace",
        overflowX: "auto",
        code: {
          color: "inherit",
        },
      },
      code: {
        fontFamily: "monospace",
        fontSize: "inherit",
      },
      table: {
        width: "100%",
        borderCollapse: "separate",
        borderSpacing: 0,
      },
      th: {
        textAlign: "left",
        borderBottomStyle: "solid",
      },
      td: {
        textAlign: "left",
        borderBottomStyle: "solid",
      },
      img: {
        maxWidth: "100%",
      },
    },
  },
}
